<template>
  <div>
    <div
      v-if="!will_register"
      class="w-full p-2 mx-auto bg-white border border-gray-300 shadow sm:rounded-lg sm:p-4 sm:w-11/12 md:w-5/6"
    >
      <div class="flex flex-col items-center space-y-4 font-semibold">
        <p>It looks as though you have not visited TVAP before.</p>
        <p>Would you like to register with us?</p>
        <div class="flex items-center justify-center pt-4 space-x-8">
          <button
            class="px-3 py-2 text-base font-medium text-white duration-300 transform bg-green-500 rounded shadow w-28 hover:opacity-80"
            @click="will_register = true"
          >
            Yes please
          </button>
          <a
            :href="retURL"
            class="inline-block text-sm text-center text-gray-500 underline w-28 hover:text-opacity-80 hover:opacity-70"
            >No thanks</a
          >
        </div>
      </div>
    </div>
    <div
      v-else
      class="w-full p-2 mx-auto bg-white border border-gray-300 shadow sm:rounded-lg sm:p-4 sm:w-11/12 md:w-5/6"
    >
      <h1
        class="text-sm font-semibold tracking-wide text-gray-800 sm:text-base"
      >
        Please enter the following information and we will call you to complete
        the registration process.
      </h1>

      <!-- -------------------------------------------------------------------------  -->
      <!-- production org                                                             -->
      <!-- https://webto.salesforce.com/servlet/servlet.WebToLead                     -->
      <!-- name="oid" value="00D1r000001o8VI"                                         -->
      <!-- -------------------------------------------------------------------------  -->

      <form ref="w2lform" :action="formSubmitAction" method="POST">
        <input type="hidden" name="oid" :value="oid" />
        <input type="hidden" name="retURL" :value="retURL" />
        <input type="hidden" name="company" value="Individual" />

        <!--  ----------------------------------------------------------------------  -->
        <!--  NOTE: These fields are optional debugging elements. Please uncomment    -->
        <!--  these lines if you wish to test in debug mode.                          -->
        <input type="hidden" name="debug" value="1" />
        <input type="hidden" name="debugEmail" value="ian@exegi.software" />
        <!--  ----------------------------------------------------------------------  -->

        <div
          class="flex flex-col items-center justify-center w-full sm:space-x-6 sm:flex-row"
        >
          <div class="flex flex-col w-full my-2">
            <label for="first_name" class="text-sm font-medium text-gray-700"
              >First Name</label
            ><input
              id="first_name"
              v-model="firstname"
              maxlength="40"
              name="first_name"
              type="text"
              class="w-full border-gray-300 rounded shadow focus:outline-none focus:ring-2 focus:ring-indigo-300"
              :class="{
                'ring-2 ring-red-300 border-red-300': firstNameError,
              }"
            />
          </div>

          <div class="flex flex-col w-full my-2">
            <label for="last_name" class="text-sm font-medium text-gray-700"
              >Last Name</label
            ><input
              id="last_name"
              v-model="lastname"
              maxlength="80"
              name="last_name"
              type="text"
              class="w-full border-gray-300 rounded shadow"
              :class="{ 'ring-2 ring-red-300 border-red-300': lastNameError }"
            />
          </div>
        </div>

        <div
          class="flex flex-col items-center justify-center sm:space-x-6 sm:flex-row"
        >
          <div class="flex flex-col w-full my-2">
            <label for="phone" class="text-sm font-medium text-gray-700"
              >Mobile number</label
            ><input
              id="mobile"
              v-model="phone"
              maxlength="40"
              name="mobile"
              type="text"
              class="w-full border-gray-300 rounded shadow"
              :class="{ 'ring-2 ring-red-300 border-red-300': phoneError }"
            />
          </div>

          <div class="flex flex-col w-full my-2">
            <label for="email" class="text-sm font-medium text-gray-700"
              >Email</label
            ><input
              id="email"
              v-model="email"
              maxlength="80"
              name="email"
              type="text"
              class="w-full border-gray-300 rounded shadow"
              :class="{ 'ring-2 ring-red-300 border-red-300': emailError }"
            />
          </div>
        </div>

        <div class="flex flex-col w-full my-2 space-y-2">
          <div class="flex items-center text-sm font-medium text-gray-700">
            <span class="inline-block w-5/6 lg:w-11/12"
              >Does your booking include one or more people with SEN?</span
            >
            <input
              id="00N3M000000ebkS"
              name="00N3M000000ebkS"
              type="checkbox"
              value="1"
              class="focus:outline-none focus:ring-2 focus:ring-indigo-300"
            />
          </div>

          <div class="flex items-center text-sm font-medium text-gray-700">
            <span class="inline-block w-5/6 lg:w-11/12"
              >Are you booking for one or more people children (under 17)?</span
            >
            <input
              id="00N3M000000ebkX"
              name="00N3M000000ebkX"
              type="checkbox"
              value="1"
              class="focus:outline-none focus:ring-2 focus:ring-indigo-300"
            />
          </div>
        </div>

        <div
          v-if="error.length"
          class="mt-2 font-semibold text-center text-red-600 md:mt-4"
        >
          Please enter all fields
        </div>

        <!-- Tell Salesforce this came from the bookings app -->
        <input id="lead_source" name="lead_source" type="hidden" value="App" />

        <div
          class="flex items-center justify-center mt-4 mb-2 space-x-6 md:mt-6"
        >
          <button
            class="px-4 py-2 text-white rounded-md shadow focus:outline-none bg-tvap-green hover:opacity-80"
            @click.prevent="submit"
          >
            Submit
          </button>
          <a
            :href="retURL"
            class="text-sm text-gray-500 underline lowercase hover:text-opacity-80"
            >cancel</a
          >
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import stateProps from '@/components/mixins/state-props';

export default {
  mixins: [stateProps],

  data() {
    return {
      email: this.current.context.userid,
      error: [],
      firstname: '',
      lastname: '',
      phone: '',
      will_register: false,
    };
  },

  computed: {
    emailError() {
      return this.error.includes('email');
    },
    formSubmitAction() {
      return process.env.VUE_APP_SALESFORCE_URL;
    },
    firstNameError() {
      return this.error.includes('firstname');
    },
    lastNameError() {
      return this.error.includes('lastname');
    },
    oid() {
      return process.env.VUE_APP_SALESFORCE_OID;
    },
    phoneError() {
      return this.error.includes('phone');
    },
    retURL() {
      return process.env.VUE_APP_RET_URL;
    },
  },

  methods: {
    submit() {
      if (this.valid()) {
        const form = this.$refs['w2lform'];
        form.submit();
      }
    },

    valid() {
      this.error = [];
      if (!this.email) {
        this.error.push('email');
      }
      if (!this.firstname) {
        this.error.push('firstname');
      }
      if (!this.lastname) {
        this.error.push('lastname');
      }
      if (!this.phone) {
        this.error.push('phone');
      }
      return this.error.length ? false : true;
    },
  },
};
</script>
