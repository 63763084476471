'use strict';

import moment from 'moment';

// presets
const saturday_sesssions = [
  [0, 4],
  [0, 9],
  [0, 14],
  [5, 9],
  [5, 14],
  [10, 14],
];

const data = {
  /**
   * TVAP offers 30 min 'slots' which (in normal times) can be booked as a
   * block.
   * During COVID these are offered as contiguous 'sessions' and users can only
   * request a place in one of these sessions e.g. 11:30 to 13:00
   */

  slots: [
    { id: 0, time: '09:30' },
    { id: 1, time: '10:00' },
    { id: 2, time: '10:30' },
    { id: 3, time: '11:00' },
    { id: 4, time: '11:30' },
    { id: 5, time: '12:00' },
    { id: 6, time: '12:30' },
    { id: 7, time: '13:00' },
    { id: 8, time: '13:30' },
    { id: 9, time: '14:00' },
    { id: 10, time: '14:30' },
    { id: 11, time: '15:00' },
    { id: 12, time: '15:30' },
    { id: 13, time: '16:00' },
    { id: 14, time: '16:30' },
  ],

  /**
   * All one-off 'special' opening hours are declared here. The lookup function
   * will check here first, if found then nothing else to do.
   * If not found, then the session times are dependent on the day of the week.
   */

  holidays: [
    {
      date: '2021-04-06',
      conditions:
        'Open for families of children with special needs (up to age 17). Limited spaces for children without special needs and their families (up to age 13)',
      slots: saturday_sesssions,
    },
    {
      date: '2021-04-07',
      conditions:
        'Open for families of children with special needs (up to age 17). Limited spaces for children without special needs and their families (up to age 13)',
      slots: saturday_sesssions,
    },
    {
      date: '2021-04-08',
      conditions:
        'Open for families of children with special needs (up to age 17). Limited spaces for children without special needs and their families (up to age 13)',
      slots: saturday_sesssions,
    },
    {
      date: '2021-04-13',
      conditions:
        'Open for families of children with special needs (up to age 17). Limited spaces for children without special needs and their families (up to age 13)',
      slots: saturday_sesssions,
    },
    {
      date: '2021-04-14',
      conditions:
        'Open for families of children with special needs (up to age 17). Limited spaces for children without special needs and their families (up to age 13)',
      slots: saturday_sesssions,
    },
    {
      date: '2021-04-15',
      conditions:
        'Open for families of children with special needs (up to age 17). Limited spaces for children without special needs and their families (up to age 13)',
      slots: saturday_sesssions,
    },
  ],

  /**
   * TVAP is only open on certain days of the week and, on those days, offers
   * 2-3 sessions depending on the day. These sessions are defined and maintained
   * here.
   */

  sessions: [
    { id: 'mo', slots: [] },
    { id: 'tu', slots: [] },
    { id: 'we', slots: [] },
    { id: 'th', slots: [] },
    {
      id: 'fr',
      slots: [
        [1, 7],
        [7, 13],
      ],
    },
    {
      id: 'sa',
      slots: saturday_sesssions,
    },
    { id: 'su', slots: [] },
  ],
};

/**
 * API using the data declared above.
 *
 * slotsForDate(d) => returns a nested array of the session start and end times
 *                    available on the supplied date.
 */

function slotsForDate(date) {
  /**
   * Convert a nested array of slot ids into a nested array of slot objects
   * i.e. [[0,1]] => [[{id:0,time:'09:00'}, {id:1, time: '10:00'}]]
   */
  // outer = [[1,2],[3,4]]
  const times = outer => {
    // inner = [1,2]
    return outer.reduce((acc, inner) => {
      acc.push(
        inner.reduce((acc, id) => {
          const obj = data.slots.find(slot => slot.id === id);
          acc.push(obj);
          return acc;
        }, [])
      );
      return acc;
    }, []);
  };

  const special = data.holidays.find(s => s.date === date);
  if (special)
    return { conditions: special.conditions, slots: times(special.slots) };

  const dow = moment(date)
    .format('dd')
    .toLowerCase();

  return {
    conditions: null,
    slots: times(data.sessions.find(session => session.id === dow).slots),
  };
}

export { slotsForDate };
