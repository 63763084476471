'use strict';
import { http } from '@/lib';
import moment from 'moment';

export default {
  /**
   * Invoked directly by the BookingAvailability view to check capacity against
   * Salesforce.
   * If it's ok then use the app controller supplied cb to push to the next
   * route. If no availability then the process stays within the view.
   */
  availability: async function(booking) {
    const { date, users, arrive, leave } = booking;

    // Access Salesforce via server API
    const resp = await http.get(
      `/available/${moment(date).format('YYYY-MM-DD')}?arrive=${
        arrive.time
      }&leave=${leave.time}&users=${users}`
    );

    if (!resp.data.success) return false;

    return resp.data.results;
  },
};
