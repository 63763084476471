'use strict';
import axios from 'axios';
import shajs from 'sha.js';
import store from './store';

const encrypt = p =>
  shajs('sha256')
    .update(p)
    .digest('hex');

const finder = vm => async userid => {
  let dbuser;
  let sfuser;
  try {
    const res = await vm.get(`/user/${userid}`);
    dbuser = res.data.user;
    sfuser = null;
  } catch (error) {
    try {
      const res = await vm.get(`/thamesvalley/salesforce/${userid}`);
      sfuser = res.data.user;
      dbuser = null;
    } catch (error) {
      dbuser = null;
      sfuser = null;
    }
  }
  return {
    db: dbuser ? true : false,
    sf: sfuser ? true : false,
    found: !!dbuser || !!sfuser,
    user: dbuser ? dbuser : sfuser ? sfuser : null,
  };
};

/**
 *
 */
const http = (function() {
  const SERVER_OPTS =
    process.env.NODE_ENV === 'development'
      ? { protocol: 'https', server: 'dev.exegi.software' }
      : { protocol: 'https', server: 'api.exegi.software' };

  // An Axios instance that only addresses a specified server.
  const baseURL = `${SERVER_OPTS.protocol}://${SERVER_OPTS.server}/thamesvalley/v1`;
  console.log(`Connecting to: ${baseURL}`);
  const h = axios.create({ baseURL });

  // Public API definitions
  const methods = { get: h.get, put: h.put, post: h.post };
  const removeToken = () => delete h.defaults.headers.common['Authorization'];
  const setToken = function(t) {
    h.defaults.headers.common['Authorization'] = t;
  };
  const getToken = () => h.defaults.headers.common['Authorization'];

  // Added a response interceptor to handle 401 response when session token
  // has expired.
  h.interceptors.response.use(
    response => {
      if (!response.data.success) {
        return Promise.reject({ message: response.data.message });
      }
      return response;
    },
    error => {
      return Promise.reject({
        status: error.response.status,
        ...error.response.data,
      });
    }
  );

  // Public API declaration
  return {
    ...methods,
    removeToken,
    getToken,
    setToken,
  };
})();

export { encrypt, finder, http, store };
