<template>
  <div class="flex flex-col justify-center h-screen mx-auto">
    <div
      class="flex flex-col justify-start min-h-screen p-0 bg-white sm:px-6 lg:px-0"
    >
      <div class="w-full sm:mx-auto sm:max-w-md">
        <div class="w-full sm:mx-auto sm:max-w-md">
          <h2
            class="py-4 mt-4 mb-6 text-base font-bold tracking-wider text-center text-white bg-opacity-75 sm:rounded bg-tvap-green md:text-xl sm:leading-9"
          >
            We potentially have availability
          </h2>
          <h3 class="text-center">
            Please sign in or
            <!-- <p class="mt-2 text-sm text-center text-gray-600 sm:leading-5 max-w"> -->
            <a
              href="#"
              class="font-medium transition duration-150 ease-in-out opacity-100 text-tvap-pink hover:opacity-80 focus:outline-none focus:underline"
              @click.prevent="register"
            >
              create a new account
            </a>
            <!-- </p> -->
          </h3>
        </div>

        <div
          class="px-4 py-4 mt-2 bg-white sm:shadow xs:mt-4 xs:py-6 sm:rounded-lg sm:px-10"
        >
          <div>
            <label
              for="email"
              class="block text-sm font-medium leading-5 text-gray-700"
            >
              Email address
            </label>
            <div class="mt-1 rounded-md shadow-sm">
              <input
                id="email"
                v-model="userid"
                type="email"
                required
                class="block w-full px-3 py-1 placeholder-gray-400 transition duration-150 ease-in-out border border-gray-300 rounded-md appearance-none xs:py-2 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
              />
            </div>
          </div>

          <div class="mt-3 xs:mt-6">
            <label
              for="password"
              class="block text-sm font-medium leading-5 text-gray-700"
            >
              Password
            </label>
            <div class="mt-1 rounded-md shadow-sm">
              <input
                id="password"
                v-model="password"
                type="password"
                required
                class="block w-full px-3 py-1 placeholder-gray-400 transition duration-150 ease-in-out border border-gray-300 rounded-md appearance-none xs:py-2 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
              />
            </div>
          </div>

          <div
            class="flex flex-col items-center justify-between mt-6 lg:flex-row"
          >
            <div class="flex items-center hidden">
              <input
                id="persist"
                v-model="persist"
                name="persist"
                type="checkbox"
                class="w-4 h-4 text-indigo-600 transition duration-150 ease-in-out form-checkbox"
              />
              <label
                for="persist"
                class="block ml-2 text-sm leading-5 text-gray-900"
              >
                Stay logged in
              </label>
            </div>

            <div class="text-sm leading-5">
              <a
                href="#"
                class="font-medium transition duration-150 ease-in-out opacity-100 text-tvap-pink hover:opacity-80 focus:outline-none focus:underline"
                @click.prevent="forgotten"
              >
                Forgot your password?
              </a>
            </div>
          </div>

          <div class="mt-6">
            <span class="block w-full rounded-md shadow-sm">
              <button
                class="flex justify-center w-full px-4 py-2 text-sm font-medium text-white transition duration-150 ease-in-out border border-transparent rounded-md opacity-100 bg-tvap-pink hover:opacity-80 focus:outline-none focus:border-blue-600 focus:shadow-outline-blue-600 active:bg-tvap-pink"
                @click="logon"
              >
                Sign in
              </button>
            </span>
          </div>
          <div
            v-if="current.matches('authenticating.noauth')"
            class="flex mt-2 text-red-600"
          >
            <svg
              class="hidden w-6 h-6 p-1 sm:flex"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                class="fill-current"
                d="M2.93 17.07A10 10 0 1117.07 2.93 10 10 0 012.93 17.07zM9 5v6h2V5H9zm0 8v2h2v-2H9z"
              />
            </svg>
            <span class="text-sm font-semibold"
              >Invalid user id or password</span
            >
          </div>
        </div>
      </div>
    </div>

    <!--
    <footer class="flex justify-center block w-full mt-16">
      <span class="ml-4 text-gray-500 text-xxs md:text-xxs"
        >By signing in to TVAP, you agree to our
        <a class="underline" href="#"> terms of service.</a></span
      >
    </footer>
      -->
  </div>
</template>

<script>
/* eslint-disable-next-line */
import { http } from '@/lib';
/* eslint-disable-next-line */
import jwtdecode from 'jwt-decode';
import stateProps from '@/components/mixins/state-props';

export default {
  mixins: [stateProps],

  data() {
    return {
      userid: '',
      password: '',
      persist: true, //TODO can this be set based on what is in the token?
      showing: false,
    };
  },

  methods: {
    forgotten() {
      this.$service.send('FORGOT');
    },

    async logon() {
      if (this.userid && this.password) {
        try {
          const resp = await http.post('password', {
            id: this.userid,
            pw: this.password,
            persist: this.persist,
          });
          http.setToken(resp.data.token);
          this.$service.send('RESOLVE', {
            token: resp.data.token,
          });
        } catch (error) {
          this.$service.send('REJECT');
        }
      }
    },

    register() {
      this.$service.send('REGISTER');
    },

    togglePassword() {
      // show/hide the password being entered
      const input = this.$refs['password'];
      input.type = input.type === 'text' ? 'password' : 'text';

      // set a flag to control which icon is used to toggle the password display
      this.showing = this.$refs['password'].type === 'text';
    },
  },
};
</script>

<style lang="scss" scoped></style>
