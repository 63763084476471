<template>
  <div
    class="overflow-hidden bg-white border border-gray-300 shadow sm:rounded-lg"
  >
    <div
      class="px-2 py-3 border-b border-gray-200 bg-tvap-green sm:px-4 sm:py-5 sm:px-6"
    >
      <div class="flex flex-col items-start justify-center tracking-wide">
        <h3
          class="text-sm font-semibold text-white sm:text-base md:text-xl sm:leading-6"
        >
          <span class="hidden tracking-wider sm:inline"
            >Enquiry for {{ booking.date | day }} {{ booking.date | fmt }} from
            {{ timeText }}</span
          >
          <span class="inline sm:hidden"
            >Enquiry for {{ booking.date | smallday }}
            {{ booking.date | smallfmt }} from {{ timeText }}</span
          >
        </h3>
        <p class="mt-2 text-xs md:mt-4 sm:leading-5 ">
          <label for="reset" class="mr-2 font-semibold text-white"
            >Change your enquiry?</label
          >
          <a
            id="reset"
            href="#"
            name="reset"
            class="text-white underline"
            @click.prevent="reset"
            >Click here</a
          >
        </p>
        <p
          class="flex flex-col mt-2 text-xs text-gray-600 truncate md:mt-4 sm:text-sm md:text-base"
        >
          <span class="text-white"
            >Booked by
            <span class="tracking-wider text-white">{{ info.name }}</span></span
          >
          <span
            v-for="(phone, idx) in info.phones"
            :key="idx"
            class="text-sm text-white md:text-base"
            >{{ phone }}</span
          >
        </p>
        <p class="mt-1 text-xs leading-5 sm:mt-2">
          <label for="reset" class="mr-2 font-semibold text-white"
            >Report missing or invalid information</label
          >
          <a class="text-white underline" href="#" @click.prevent="notme"
            >Click here</a
          >
        </p>
      </div>
    </div>

    <div class="flex flex-col px-6 bg-blue-300x">
      <h4
        class="py-2 mx-auto text-sm font-semibold text-gray-800 truncate md:py-4 sm:text-base"
      >
        {{ usersLabel }}
      </h4>

      <booking-grid v-cloak :users="users" @clicked="selectUser"></booking-grid>

      <div class="relative flex items-center px-6 mx-auto my-4 md:my-6">
        <div class="flex flex-col">
          <label for="carers" class="mr-3">Number of carers</label>
          <div
            v-if="nocarers"
            class="absolute bottom-0 text-xs text-orange-500"
          >
            Must be at least 1
          </div>
        </div>
        <input
          id="carers"
          type="number"
          name="carers"
          min="0"
          max="6"
          class="block py-1 pl-8 pr-1 border border-gray-400 form-input sm:text-sm sm:leading-5"
          @change="selectedCarers"
        />
      </div>

      <hr class="hidden md:block" />

      <div class="flex-shrink-0 mx-auto my-2 md:my-6">
        <a
          href="#"
          class="mr-4 text-xs font-semibold underline opacity-100 text-tvap-pink hover:opacity-80"
          @click.prevent="logoff"
          >cancel &amp; logoff</a
        >
        <span class="inline-flex rounded-md shadow-sm">
          <button
            type="button"
            class="relative inline-flex items-center px-4 py-2 text-sm font-medium leading-5 text-white border border-transparent rounded-md focus:outline-none focus:shadow-outline"
            :class="activeButton"
            :disabled="!canSubmit"
            @click="submit"
          >
            Submit
          </button>
        </span>
      </div>
    </div>

    <processing v-if="current.matches('submit_idle.processing')">
      <div
        slot="body"
        class="flex justify-center w-2/3 py-4 mx-auto bg-white rounded md:w-1/4"
      >
        <span class="text-sm text-tvap-green md:text-base xl:text-xl"
          >Processing your enquiry</span
        >
      </div>
    </processing>

    <booking-error-modal
      v-if="errorFound"
      @continue="sendReport"
      @cancel="errorFound = !errorFound"
    ></booking-error-modal>

    <submitted-modal
      v-if="current.matches('submit_idle.processed')"
      :name="info.name"
      :booking="brid"
      :meta="meta"
      :users="users.filter(u => u.selected).map(u => u.name)"
      @continue="accept"
      @cancel="logoff"
    ></submitted-modal>

    <modal v-if="current.matches('submit_idle.expired')" type="dark">
      <div slot="header">
        <span>Session timeout </span>
      </div>
      <div slot="body">
        <p>Your session has expired. Please click below to start again.</p>
      </div>
      <div slot="footer">
        <button
          class="w-16 h-10 text-white rounded-lg opacity-100 bg-tvap-pink hover:opacity-80"
          @click="login"
        >
          OK
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import BookingErrorModal from '@/components/booking-error-modal';
import BookingGrid from '@/components/booking-grid.vue';
// import BookingPanel from '@/components/booking-panel.vue';
import { http } from '@/lib';
import Modal from '@/components/modal.vue';
import Processing from '@/components/processing-modal.vue';
import moment from 'moment';
// import SelectButton from '@/components/select-button.vue';
import SubmittedModal from '@/components/submitted-modal';

/**
 * During COVID only
 * Allow households with no SEN kids to request a session. This should return
 * to Saturdays only (6) after normal service is resumed.
 */

/**
 * Which day are siblings allowed to come without any SEN family member?
 */
const SIBLING_DAYS = [2, 3, 4, 5, 6]; // 1-7
const isSiblingDay = day => SIBLING_DAYS.includes(Number(day));

/**
 * Check condition for a supplied user being a sibling.
 */
const isNotSibling = user => {
  return (
    user.sen !== '' &&
    user.sen.toLowerCase() !== 'sibling' &&
    user.sen.toLowerCase() !== 'no special need'
  );
};

/**
 * On one day per week the booking can be for siblings only.
 * Every other day, at least one user must have SEN but siblings can come
 * too.
 */
const canAccept = day => users => {
  if (isSiblingDay(day)) return true; // anyone can come on this day.
  return users.some(u => isNotSibling(u));
};

/**
 * Given a reference and an array of objects, return a copy of the object with
 * a matching ref property.
 */
const findRef = data => ref => data.filter(obj => obj.ref === ref)[0];

/**
 * General purpose sort function returned after partially applying the property
 * to be used for sorting (if no col, just assume an array of values not objects)
 **Works on strings or objects with a string property to sort on.
 */
const sorter = col => (left, right) => {
  const lval = left[col].toUpperCase();
  const rval = right[col].toUpperCase();
  return lval === rval ? 0 : lval < rval ? -1 : 1;
};

export default {
  filters: {
    day: date => moment(date).format('dddd'),
    smallday: date => moment(date).format('ddd'),
    fmt: date => moment(date).format('Do MMM'),
    smallfmt: date => moment(date).format('D/M'),
  },

  components: {
    BookingErrorModal,
    BookingGrid,
    // BookingPanel,
    Modal,
    Processing,
    // SelectButton,
    SubmittedModal,
  },

  props: {
    current: { type: Object, default: null },
  },

  data() {
    return {
      arrive: {},
      booking: null,
      brid: null,
      carers: 0,
      errorFound: false,
      info: {},
      leave: {},
      meta: {},
      nocarers: false,
      users: [],
    };
  },

  computed: {
    canSubmit() {
      //TODO This could be moved to create rather than being built every time?
      const verifySiblings = canAccept(this.booking.day);

      // Which users are currently selected in the UI?
      const selectedUsers = this.users.filter(u => u.selected);

      return (
        this.userCount() > 0 &&
        this.userCount() === selectedUsers.length &&
        verifySiblings(selectedUsers)
      );
    },

    activeButton() {
      return this.canSubmit
        ? 'bg-tvap-pink opacity-100 hover:opacity-80'
        : 'bg-gray-300 pointer-events-none';
    },

    timeText() {
      return `${this.arrive.time} to ${this.leave.time}`;
    },

    usersLabel() {
      const count = this.userCount();
      // const label = ['To submit your enquiry, choose'];
      const label = ['Please choose'];
      if (count > 1) label.push('any');
      label.push(`${count} user${count > 1 ? 's' : ''}`);
      if (!isSiblingDay(this.booking.day)) {
        label.push(count === 1 ? 'with SEN' : 'of which at least 1 has SEN');
      }
      return label.join(' ');
    },
  },

  async created() {
    if (
      // eslint-disable-next-line no-prototype-builtins
      this.current.context.hasOwnProperty('booking') &&
      this.current.context['booking'] !== undefined
    ) {
      this.booking = this.current.context['booking'];
    } else {
      this.booking = this.current.context.token.booking || {};
    }

    this.arrive = { ...this.booking.arrive };
    this.leave = { ...this.booking.leave };
    this.booking.day = moment(this.booking.date).format('E'); // 1-7

    if (!this.booking.users) {
      return this.$service.send('REJECT');
    }

    /**
     * Retrieving all households just to build a list of all the users (siblings
     * and SEN users)
     */
    const sortfn = sorter('name');
    const households = await this.loadHouseholds();
    this.users = households
      .map(h => h.users.map(u => u))
      .flat()
      .sort(sortfn);

    this.info = await this.loadContact();
  },

  methods: {
    accept() {
      this.$service.send('RESOLVE');
    },

    reset() {
      this.$service.send('CANCEL');
    },

    /**
     * Each TVAP contact is associated with 1+ households in Salesforce.
     * A household is an account with type=household as opposed to organisation.
     *
     * Get *all* the households (in majority of cases there will be only 1)
     */
    //TODO move the db reads into the state machine?
    async loadHouseholds() {
      const households = await http.get(`household/user/${this.userEmail()}`);

      return households.data.results.map(h => {
        return {
          name: h.name,
          ref: h.id,
          users: h.users.map(u => ({
            alreadyBooked: false,
            ref: u.id,
            name: u.name,
            sen: u.sen || '',
            selected: false,
          })),
          selected: false,
        };
      });
    },

    /**
     * The contact/person represented in Salesforce by the email address used
     * to login.
     */
    //TODO move the db reads into the state machine?
    async loadContact() {
      const data = (await http.get(`person/${this.userEmail()}`)).data;
      if (!data.success) return {};
      const person = data.results;
      // Don't assume the SF person has any kind of address or phone numbers
      const { street = '', city = '', postalCode: postcode = '' } =
        person.mailingaddress || {};
      return {
        ref: person.id,
        account: person.accountid,
        name: person.name,
        address: [street, city, postcode],
        phones: [`${person.mobilephone || ''}`, `${person.homephone || ''}`],
      };
    },

    login() {
      this.$service.send('LOGIN');
    },

    logoff() {
      this.$service.send('LOGOFF');
    },

    notinlist() {
      this.errorFound = true;
    },

    notme() {
      this.errorFound = true;
    },

    request() {
      return {
        person: {
          id: this.info.ref,
          account: this.info.account,
          email: this.userEmail(),
        },
        date: this.booking.date,
        arrive: this.arrive.time,
        leave: this.leave.time,
        users: this.users.filter(u => u.selected).map(u => u.ref),
        carers: this.carers,
      };
    },

    selectedCarers(ev) {
      this.carers = ev.target.value;
      this.nocarers = this.carers < 1 ? true : false;
    },

    async selectUser(ref) {
      // reset all users in case any of them had errors showing
      this.users.forEach(u => (u.alreadyBooked = false));

      // Any number of users can be selected but enquiry can only be submitted
      // when the selected number equals the number the enquiry was started for.
      const user = findRef(this.users)(ref);
      const booked = await http.get(`booking/requestuser/${ref}`);
      if (booked.data.results.includes(this.booking.date)) {
        return (user.alreadyBooked = true);
      }
      user.selected = !user.selected;
    },

    sendReport(reasons) {
      this.errorFound = false;
      this.$service.send('EMAIL', {
        data: {
          name: this.info.name,
          userid: this.info.ref,
          to: process.env.VUE_APP_ADMIN_EMAIL.split(','), // admin user(s)
          email: this.userEmail(),
          reasons,
        },
      });
    },

    async submit() {
      if (this.carers < 1) {
        this.nocarers = true;
        return;
      }

      this.$service.send('UPDATE', this.booking);

      try {
        const res = await http.post('booking/request', this.request());
        if (res.data.results.success) {
          this.brid = res.data.results.brid;
          this.meta = res.data.results.meta;
          this.$service.send('RESOLVE');
        }
      } catch (error) {
        this.$service.send('REJECT');
      }
    },

    userEmail() {
      return this.current.context.token.userid;
    },

    userCount() {
      return Number(this.booking.users);
    },
  },
};
</script>

<style scoped>
[v-cloak] {
  display: none;
}
</style>
