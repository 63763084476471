<template>
  <div class="flex flex-col mx-auto h-screen justify-center">
    <div
      class="min-h-screen bg-gray-200 flex flex-col justify-start p-0 sm:px-6 lg:px-0"
    >
      <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 class="text-center text-xl leading-9 font-bold text-gray-700">
            Reset your password
          </h2>
        </div>

        <div class="bg-white mt-4 py-6 px-4 shadow sm:rounded-lg sm:px-10">
          <div>
            <label
              for="email"
              class="block text-sm font-medium leading-5 text-gray-700"
              >Email</label
            >
            <div class="mt-1 relative rounded-md shadow-sm">
              <div
                class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
              >
                <svg
                  class="h-5 w-5 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884zM18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <input
                id="email"
                v-model="userid"
                class="form-input block w-full pl-10 sm:text-sm sm:leading-5"
                placeholder="you@example.com"
              />
            </div>
          </div>

          <div v-if="error" class="mt-4 flex flex-col">
            <span class="self-center font-semibold text-orange-700 text-sm"
              >This email address has not been registered</span
            >
            <div class="mt-6 flex justify-between items-center">
              <div class="rounded-md shadow-sm">
                <button
                  class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                  @click="repeat"
                >
                  Try again
                </button>
              </div>
              <div class="rounded-md shadow-sm">
                <button
                  class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                  @click="create"
                >
                  Create new account
                </button>
              </div>
              <div>
                <a
                  href="#"
                  class="underline text-gray-500 text-sm"
                  @click.prevent="cancel"
                  >cancel</a
                >
              </div>
            </div>
          </div>
          <div v-else class="mt-6 flex justify-center items-center">
            <div class="rounded-md shadow-sm">
              <button
                class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                @click="reset"
              >
                Reset password
              </button>
            </div>
            <div class="ml-6">
              <a
                href="#"
                class="underline text-gray-500 text-sm"
                @click.prevent="cancel"
                >cancel</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <processing-modal v-if="current.matches('forgotten.submitted')">
      <div
        slot="body"
        class="flex flex-col justify-center items-center text-center mx-auto w-2/3 md:w-1/3 px-1 md:px-6 py-2 md:py-4 md:rounded bg-white"
      >
        <p class="text-gray-500 text-xs sm:text-sm md:text-base">
          You will soon receive an email with details of how to finish resetting
          your password.
        </p>
        <p class="mt-3 text-gray-500 italic text-xs sm:text-sm md:text-base">
          Please don't forget to check your spam folder
        </p>
        <div class="mt-2 md:mt-5">
          <button
            class="py-2 px-8 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
            @click="accept"
          >
            OK
          </button>
        </div>
      </div>
    </processing-modal>
  </div>
</template>

<script>
import { http } from '@/lib';
import ProcessingModal from '@/components/processing-modal.vue';
import stateProps from '@/components/mixins/state-props';

export default {
  components: {
    ProcessingModal,
  },

  mixins: [stateProps],

  data() {
    return {
      error: false,
      userid: '',
    };
  },

  methods: {
    accept() {
      this.$service.send('DISMISS');
    },

    cancel() {
      this.accept();
    },

    create() {
      this.$service.send('CREATE');
    },

    repeat() {
      this.error = false;
      this.userid = '';
    },

    async reset() {
      //* This code was written before state machines so I am continuing to
      //* manage state in the component, just to get it wrapped up.
      //TODO Refactor.
      if (this.userid) {
        try {
          await http.post('forgot', {
            id: this.userid,
            user: { id: this.userid },
            booking: this.current.context.booking,
          });
          this.$service.send('SUBMIT', { userid: this.userid });
        } catch (error) {
          console.log(error.message, error.data);
          this.error = true;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
