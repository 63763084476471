<template>
  <div>
    <ul class="grid grid-cols-1 gap-2 sm:gap-4 md:gap-6 sm:grid-cols-2">
      <li
        v-for="user in users"
        :key="user.ref"
        class="col-span-1 bg-white border border-gray-200 rounded-lg shadow"
      >
        <booking-grid-card
          :user="user"
          :selected="user.selected"
          @clicked="clicked(user.ref)"
        ></booking-grid-card>
      </li>
    </ul>
  </div>
</template>

<script>
import BookingGridCard from './booking-grid-card.vue';

export default {
  components: {
    BookingGridCard,
  },

  props: {
    users: { type: Array, default: () => [] },
  },

  methods: {
    clicked(ref) {
      this.$emit('clicked', ref);
    },
  },
};
</script>
