<template>
  <div class="relative flex flex-col p-6" @click="clicked">
    <div class="flex items-center justify-between w-full space-x-6">
      <div class="flex-1 truncate">
        <div class="flex items-center space-x-3">
          <h3 class="text-sm font-medium leading-5 text-gray-900 truncate">
            {{ user.name }}
          </h3>
        </div>
      </div>
      <span class="px-1" :class="selectedClass">
        <svg viewBox="0 0 20 20" fill="currentColor" class="w-12">
          <path
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </span>
    </div>

    <p
      v-if="user.alreadyBooked"
      class="absolute mx-auto text-xs font-semibold text-orange-500 bottom-4 sm:text-sm"
    >
      Already booked on that date
    </p>
  </div>
</template>

<script>
export default {
  props: {
    user: { type: Object, default: () => {} },
    selected: { type: Boolean, default: false },
  },

  computed: {
    selectedClass() {
      return this.selected ? 'text-tvap-pink opacity-100' : 'text-gray-300';
    },
  },

  methods: {
    clicked() {
      this.$emit('clicked');
    },
  },
};
</script>

<style scoped></style>
