import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);

// Router is only used when clicking the email link to register or reset
// password.
// No component because App.vue will use the route name to invoke the state
// machine.
const routes = [
  { path: '/welcome', name: 'password' },
  { path: '/reset', name: 'password' },
];

const router = new VueRouter({
  routes,
});

export default router;
