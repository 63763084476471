import Vue from 'vue';
import App from './App.vue';
import router from './router';
import bookingMachine from '@/machines/BookingMachine';
import { interpret } from 'xstate';
import '@/assets/css/styles.css';

Vue.config.productionTip = false;

// Every view uses the xstate service to send events to the machine.
// The current state i stracked in App.vue and passed via props to each view.
Vue.prototype.$service = interpret(bookingMachine);

new Vue({
  router,
  render: h => h(App),
}).$mount('#app');
