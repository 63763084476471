'use strict';
import jwtDecode from 'jwt-decode';

const token = {
  id: 'TOKEN',
  delete: () => localStorage.removeItem(token.id),
  get: () => jwtDecode(token.raw()),
  raw: () => JSON.parse(localStorage.getItem(token.id)),
  set: t => localStorage.setItem(token.id, JSON.stringify(t)),
};

export default { token };
