<template>
  <transition name="modal">
    <div class="modal-mask fixed z-50 top-0 left-0 w-full h-screen table">
      <div class="table-cell align-middle">
        <div class="modal-container z-40 relative">
          <slot name="body"></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'info',
    },
  },

  data() {
    return {
      styles: {
        info: {
          header: ['bg-gray-700', 'text-white'],
          // body: ['bg-gray-100', 'border-gray-100', 'text-gray-600'],
          footer: ['bg-gray-100'],
        },
        warning: {
          header: ['bg-orange-500', 'text-orange-100'],
          body: ['bg-orange-200', 'border-orange-300'],
          footer: ['bg-orange-200'],
        },
        error: {
          header: ['bg-red-600', 'text-red-100'],
          body: ['bg-white', 'border-white', 'text-red-500'],
          footer: ['bg-white'],
        },
      },
    };
  },

  computed: {
    // bodyClasses() {
    //   return this.getStyle()['body'];
    // },
    // footerClasses() {
    //   return this.getStyle()['footer'];
    // },
    // headerClasses() {
    //   return this.getStyle()['header'];
    // },
  },

  methods: {
    accept() {
      this.$emit('accept');
    },

    getStyle() {
      return this.styles[this.type] || this.styles.info;
    },
  },
};
</script>

<style>
.modal-cover {
  background-color: rgba(0, 0, 0, 1);
  transition: opacity 0.2s ease;
}
.modal-mask {
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.2s ease;
}

.modal-container {
  transition: all 0.2s ease;
}

/*
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
