<template>
  <div class="flex items-center">
    <input
      :id="name"
      :disabled="disabled"
      :checked="value"
      type="checkbox"
      class="form-checkbox h-4 w-4 text-gray-500 transition duration-150 ease-in-out"
      :class="[entered ? '' : 'border border-red-500']"
      @change="update"
    />
    <label
      for="name"
      class="ml-4 block text-xs sm:text-sm font-normal leading-5 text-gray-700"
      :class="{ required: !entered }"
    >
      <slot></slot>
    </label>
  </div>
</template>

<script>
/**
 * * Note
 * For a checkbox the 'required' prop means that it must be ticked i.e. true.
 * If not required then it may be left unticked and still be valid.
 */
export default {
  props: {
    mode: { type: String, default: 'VIEW' },
    name: { type: String, default: '' },
    required: { type: Boolean, default: false },
    // eslint-disable-next-line
    value: { type: Boolean },
  },

  computed: {
    disabled() {
      return this.mode.toUpperCase() === 'VIEW';
    },

    entered() {
      return this.required ? this.value : true;
    },
  },

  methods: {
    update(ev) {
      this.$emit('input', ev.target.checked);
    },
  },
};
</script>

<style scoped>
.required::after {
  content: '*';
  margin-left: 3px;
  color: red;
}
</style>
