<template>
  <transition name="modal">
    <div class="fixed top-0 left-0 z-50 table w-full h-screen modal-mask">
      <div class="table-cell align-middle">
        <div class="relative z-40 modal-container">
          <div
            class="w-full mx-auto overflow-hidden text-sm text-gray-600 bg-white md:w-2/3 md:rounded-lg"
          >
            <header
              class="py-1 pl-2 text-sm font-semibold bg-gray-100 border-b border-gray-200 md:pl-8 md:py-2 md:text-lg"
            >
              <span>Error Report</span>
            </header>
            <section class="px-2 pt-1 pb-2 md:px-8 md:pt-4 md:pb-6 ">
              <h4 class="my-1 text-xs font-semibold xs:text-sm sm:text-base">
                We are sorry that some of the information is incorrect
              </h4>
              <h6 class="mt-0 text-xs md:mt-4 sm:text-sm md:text-base">
                Please tell us what is wrong:
              </h6>
              <div class="ml-3">
                <p class="mt-0 md:mt-3">
                  <checkbox
                    mode="EDIT"
                    name="reason-1"
                    @input="reason(1, $event)"
                    >All of my children, or those for whom I care, are not
                    showing in the list</checkbox
                  >
                </p>
                <p class="mt-0 md:mt-2">
                  <checkbox
                    mode="EDIT"
                    name="reason-2"
                    @input="reason(2, $event)"
                    >Some or all of the children in the list are not mine or I
                    have not been a carer for them</checkbox
                  >
                </p>
                <p class="mt-0 md:mt-2">
                  <checkbox
                    mode="EDIT"
                    name="reason-3"
                    @input="reason(3, $event)"
                    >My name is wrong</checkbox
                  >
                </p>
                <p class="mt-0 md:mt-2">
                  <checkbox
                    mode="EDIT"
                    name="reason-4"
                    @input="reason(4, $event)"
                    >One of the children for whom I care is not documented as
                    SEN</checkbox
                  >
                </p>
              </div>
              <p class="mt-1 text-xs md:mt-3 sm:text-sm md:text-base">
                We will look into this and may contact you for more information.
              </p>
              <p class="mt-1 text-xs md:mt-3 sm:text-sm md:text-base">
                Click Send to submit this form. You will then be returned to the
                enquiry screen.
              </p>
              <p class="mt-1 text-xs md:mt-3 sm:text-sm md:text-base">
                If you cannot complete your enquiry because of these errors
                please give us a ring on {{ tvapPhone }} to make your booking.
              </p>
              <p class="mt-1 text-xs md:mt-3 sm:text-sm md:text-base">
                Best Wishes
              </p>
              <p class="mt-1 text-xs md:mt-3 sm:text-sm md:text-base">
                TVAP
              </p>
              <div class="flex justify-center mt-2 mb-3 md:mt-6">
                <button
                  class="px-4 py-2 mr-4 font-semibold text-white rounded-md shadow-lg opacity-100 bg-tvap-pink hover:opacity-80"
                  @click="click(true)"
                >
                  Send Error Report
                </button>
                <button
                  class="ml-4 font-normal underline opacity-100 text-tvap-pink hover:opacity-80"
                  @click="click(false)"
                >
                  cancel
                </button>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Checkbox from '@/components/global/forms/checkbox';
export default {
  components: {
    Checkbox,
  },

  data() {
    return {
      reasons: [],
      tvapPhone: '01628 628599',
    };
  },

  methods: {
    click(next) {
      this.$emit(next ? 'continue' : 'cancel', this.reasons);
    },

    reason(...args) {
      const [no, checked] = args;
      // Always remove the clicked reason and only put it back if checked.
      const idx = this.reasons.findIndex(el => el === no);
      if (idx >= 0) {
        this.reasons.splice(idx, 1);
      }
      if (checked) this.reasons.push(no);
    },
  },
};
</script>

<style>
.modal-cover {
  background-color: rgba(0, 0, 0, 1);
  transition: opacity 0.2s ease;
}
.modal-mask {
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.2s ease;
}

.modal-container {
  transition: all 0.2s ease;
}

/*
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
