'use strict';

export default {
  props: {
    current: {
      type: Object,
      default: null,
    },
  },
};
