<template>
  <div class="flex flex-col justify-center h-screen mx-auto">
    <div
      class="flex flex-col justify-start min-h-screen p-0 bg-gray-200 sm:px-6 lg:px-0"
    >
      <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <div class="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
          <h2
            class="text-sm font-bold leading-9 text-center text-gray-700 xs:text-base sm:text-xl"
          >
            Enter a valid email address
          </h2>
        </div>

        <div class="px-4 py-6 mt-4 bg-white shadow sm:rounded-lg sm:px-10">
          <div>
            <label
              for="email"
              class="block text-sm font-medium leading-5 text-gray-700"
              >Email?</label
            >
            <div class="relative mt-1 rounded-md shadow-sm">
              <div
                class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
              >
                <svg
                  class="w-5 h-5 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884zM18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <input
                id="email"
                v-model="userid"
                type="text"
                class="block w-full pl-10 form-inputx sm:text-sm sm:leading-5"
                placeholder="you@example.com"
                @focus="focus"
              />
            </div>
          </div>

          <div
            v-if="current.matches('registering.cannot_register')"
            class="mt-4"
          >
            <div class="flex flex-col items-center justify-center text-sm">
              <span class="self-center text-sm font-semibold text-green-600"
                >To register this email address, please call TVAP on {{ phone }}
              </span>
              <div class="flex justify-center w-full mt-4">
                <button
                  class="px-4 py-2 text-sm font-medium text-white transition duration-150 ease-in-out border border-transparent rounded-md opacity-100 bg-tvap-pink hover:opacity-80 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                  @click="action('cancel')"
                >
                  Cancel enquiry
                </button>
              </div>
            </div>
          </div>

          <div
            v-else-if="current.matches('registering.already_registered')"
            class="mt-2"
          >
            <div class="flex flex-col items-center justify-center text-sm">
              <span class="text-orange-800"
                >This email has already been registered
              </span>
              <div class="flex items-center justify-between mt-2">
                <button
                  class="px-4 py-2 text-sm font-medium text-white transition duration-150 ease-in-out border border-transparent rounded-md opacity-100 bg-tvap-pink hover:opacity-80 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                  @click="action('reset')"
                >
                  Reset password
                </button>
                <div class="ml-6">
                  <a
                    href="#"
                    class="text-sm text-gray-500 underline"
                    @click.prevent="action('cancel')"
                    >cancel</a
                  >
                </div>
              </div>
            </div>
          </div>

          <div v-else-if="current.matches('registering.retry')" class="mt-8">
            <span class="text-sm text-orange-700"
              >Please enter a valid email address
            </span>
          </div>

          <div v-else class="flex items-center justify-center mt-6">
            <div class="rounded-md shadow-sm">
              <button
                class="flex justify-center w-full px-4 py-2 text-sm font-medium text-white transition duration-150 ease-in-out border border-transparent rounded-md opacity-100 bg-tvap-pink hover:opacity-80 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                @click="register"
              >
                Sign up
              </button>
            </div>
            <div class="ml-6">
              <a
                href="#"
                class="text-sm text-gray-500 underline"
                @click.prevent="action('cancel')"
                >cancel</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <processing-modal v-if="current.matches('registering.submitted')">
      <div
        slot="body"
        class="flex flex-col items-center justify-center w-2/3 px-1 py-2 mx-auto text-center bg-white md:w-1/3 md:px-6 md:py-4 md:rounded"
      >
        <p class="text-xs text-gray-500 sm:text-sm md:text-base">
          You will soon receive an email with details of how to complete your
          registration.
        </p>
        <p class="mt-3 text-xs italic text-gray-500 sm:text-sm md:text-base">
          Please don't forget to check your spam folder
        </p>
        <div class="mt-2 md:mt-5">
          <button
            class="px-6 py-1 text-sm font-medium text-white transition duration-150 ease-in-out border border-transparent rounded-md opacity-100 bg-tvap-pink hover:opacity-80 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
            @click="accept"
          >
            OK
          </button>
        </div>
      </div>
    </processing-modal>
  </div>
</template>

<script>
import { http } from '@/lib';
import ProcessingModal from '@/components/processing-modal.vue';
import stateProps from '@/components/mixins/state-props';

export default {
  components: {
    ProcessingModal,
  },

  mixins: [stateProps],

  data() {
    return {
      phone: process.env.VUE_APP_TVAP_PHONE,
      userid: null,
    };
  },

  methods: {
    accept() {
      this.$service.send('DISMISS');
    },

    async register() {
      if (this.userid) {
        try {
          const resp = await http.post('invite', {
            user: { id: this.userid },
            booking: this.current.context.booking,
          });
          http.setToken(resp.data.token);
          this.$service.send('RESOLVE', {
            userid: this.userid,
          });
        } catch (error) {
          this.$service.send('REJECT', {
            message: error.message,
            userid: this.userid,
          });
        }
      } else {
        this.$service.send('REJECT', { message: 'missing_userid' });
      }
    },

    action(name) {
      this.$service.send(name.toUpperCase());
    },

    focus() {
      this.userid = '';
      this.$service.send('RETRY');
    },
  },
};
</script>

<style lang="scss" scoped></style>
