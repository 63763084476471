<template>
  <div id="exegi">
    <div class="hidden bg-gray-300">
      <h1>{{ current.value }}</h1>
      <!-- <h3>{{ current.context.token }}</h3> -->
    </div>

    <!-- temp dev only -->
    <website-header v-if="development"></website-header>
    <!-- end of temp dev header -->

    <div class="bg-white shadow">
      <div class="max-w-3xl px-4 py-2 mx-auto xs:py-4 sm:px-6 lg:px-8">
        <h2
          :title="info"
          style="font-family:Chewy,cursive"
          class="text-lg font-bold leading-tight text-gray-900 xs:text-xl md:text-3xl"
        >
          Make a Booking Enquiry
        </h2>
      </div>
    </div>

    <main class="h-screen bg-cool-gray-200">
      <div class="max-w-3xl mx-auto sm:py-6 sm:px-6 lg:px-8">
        <!-- my code starts here -->
        <enquiry
          v-if="current.matches('enquiring')"
          :current="current"
        ></enquiry>

        <login
          v-if="current.matches('authenticating')"
          :current="current"
        ></login>

        <booking
          v-if="current.matches('submit_idle')"
          :current="current"
        ></booking>

        <register
          v-if="current.matches('registering')"
          :current="current"
        ></register>

        <forgotten
          v-if="current.matches('forgotten')"
          :current="current"
        ></forgotten>

        <password-set
          v-if="current.matches('set_password')"
          :current="current"
        ></password-set>

        <password-reset
          v-if="current.matches('reset_password')"
          :current="current"
        ></password-reset>

        <web-to-lead
          v-if="current.matches('new_lead')"
          :current="current"
        ></web-to-lead>
        <!-- my code ends here -->
      </div>
    </main>
  </div>
</template>

<script>
import { http } from '@/lib';
import jwtdecode from 'jwt-decode';

// Each app state is represented by a single view. Each view is implemented as
// a component.
import Booking from '@/views/Booking.vue';
import Enquiry from '@/views/Enquiry.vue';
import Forgotten from '@/views/Forgotten.vue';
import Login from '@/views/Login.vue';
import PasswordReset from '@/views/PasswordReset.vue';
import PasswordSet from '@/views/PasswordSet.vue';
import Register from '@/views/Register.vue';
import WebsiteHeader from '@/components/website-header.vue';

import WebToLead from '@/components/web-to-lead-form.vue';

export default {
  components: {
    Booking,
    Enquiry,
    Forgotten,
    Login,
    PasswordReset,
    PasswordSet,
    Register,
    WebsiteHeader,
    WebToLead,
  },

  data() {
    return {
      current: undefined,
      open: false,
    };
  },

  computed: {
    development() {
      return process.env.NODE_ENV === 'development';
    },

    info() {
      const version = require('../package.json').version;
      return `${process.env.NODE_ENV}: ${version}`;
    },
  },

  async created() {
    // Listen for transition events on the machine
    this.current = this.$service.initialState;
    this.$service
      .onTransition(state => {
        this.current = state;
      })
      .start();
    // Kick off the app by checking for server availability
    if (this.$route.name === 'password') {
      const token = this.$route.query.token;
      http.setToken(token);
      //TODO revert to encrypted tokens in state machine context
      this.$service.send('START', {
        path: 'password',
        token: jwtdecode(token),
      });
    } else {
      this.$service.send('START', { path: 'enquiry', token: '' });
    }
  },

  methods: {
    reset() {},
  },
};
</script>

<style>
footer {
  display: none !important;
}
img.mobile-logo {
  max-width: 120px !important;
}
</style>
