import { render, staticRenderFns } from "./booking-grid-card.vue?vue&type=template&id=2dc00067&scoped=true&"
import script from "./booking-grid-card.vue?vue&type=script&lang=js&"
export * from "./booking-grid-card.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2dc00067",
  null
  
)

export default component.exports