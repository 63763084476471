<template>
  <transition name="modal">
    <div class="fixed top-0 left-0 z-50 table w-full h-screen modal-mask">
      <div class="table-cell align-middle">
        <div class="relative z-40 modal-container">
          <div
            class="w-full mx-auto my-1 overflow-hidden text-sm text-gray-600 bg-white md:w-11/12 xl:w-2/3 sm:my-0 md:rounded-lg"
          >
            <header
              class="py-2 pl-8 text-lg font-semibold bg-gray-100 border-b border-gray-200"
            >
              <span>Booking Request Submitted</span>
            </header>
            <section
              class="px-1 pt-2 pb-2 text-xs sm:text-sm md:text-base sm:px-4 xl:px-8 md:pt-4 md:pb-6 "
            >
              <h6>Dear {{ name }},</h6>
              <p class="mt-3">
                Thank you for your booking request (reference
                <span class="font-bold">{{ booking }}).</span>
              </p>
              <p class="mt-2">
                You have asked to bring {{ brNames }} on {{ brDate }} between
                {{ brTimes }} with {{ brCarers }}.
              </p>
              <p class="mt-2 font-semibold">
                This request does not guarantee you a space. It is not a
                booking.
              </p>
              <p class="mt-2">
                We will contact you by email to either confirm your booking
                enquiry is now a firm booking or to inform you that,
                unfortunately, on this occassion we cannot offer you a space. If
                you do not hear from us by the end of the next working day,
                please give us a ring on {{ tvapPhone }} to check, quoting the
                above reference.
              </p>
              <div class="flex flex-col items-center mt-2 md:mt-3 lg:mt-6">
                <span class="">Do you wish to make another Request?</span>
                <div class="flex mt-1 text-white md:mt-2">
                  <button
                    class="w-20 h-8 px-6 mr-4 font-semibold rounded shadow-md opacity-100 bg-tvap-pink hover:opacity-80"
                    @click="click(true)"
                  >
                    Yes
                  </button>
                  <button
                    class="w-20 h-8 px-6 text-gray-500 bg-gray-300 rounded shadow-md hover:bg-gray-200"
                    @click="click(false)"
                  >
                    No
                  </button>
                </div>
              </div>
              <div class="flex flex-col items-center">
                <p class="mt-2 md:mt-4 lg:mt-6">
                  Best Wishes
                </p>
                <p class="mt-1 md:mt-2">
                  TVAP
                </p>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    booking: { type: String, default: '001' },
    meta: { type: Object, default: () => {} },
    name: { type: String, default: 'Sir/Madam' },
    users: { type: Array, default: () => [] },
  },

  data() {
    return {
      tvapPhone: process.env.VUE_APP_TVAP_PHONE,
    };
  },

  computed: {
    brNames() {
      const multiple = users => {
        const first = users.slice(0, users.length - 1).join(', ');
        return `${first} and ${users[users.length - 1]}`;
      };

      return this.users.length === 1
        ? // Eli
          this.users[0]
        : // e.g. Eli and Daniel or Eli, Daniel and Ian
          multiple(this.users);
    },

    brDate() {
      return moment(this.meta.date).format('ddd Do MMM Y');
    },

    brTimes() {
      return `${this.meta.startTime} and ${this.meta.endTime}`;
    },

    brCarers() {
      return `${this.meta.carers} Carer${
        Number(this.meta.carers) > 1 ? 's' : ''
      }`;
    },
  },

  methods: {
    click(next) {
      this.$emit(next ? 'continue' : 'cancel');
    },
  },
};
</script>

<style>
.modal-cover {
  background-color: rgba(0, 0, 0, 1);
  transition: opacity 0.2s ease;
}
.modal-mask {
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.2s ease;
}

.modal-container {
  transition: all 0.2s ease;
}

/*
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
