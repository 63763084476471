<template>
  <div
    class="overflow-hidden bg-white lg:border-gray-300 lg:shadow lg:border lg:rounded-md"
  >
    <!-- start of TW header -->
    <div class="w-full px-4 py-2 overflow-hidden border-t sm:border-b">
      <div class="flex items-center justify-between">
        <div class="w-full">
          <h3
            class="w-full text-sm font-bold leading-6 text-center text-gray-900 xs:text-base sm:text-lg"
          >
            Check if we have availability
          </h3>
          <p
            class="w-full mt-1 text-xs font-semibold leading-5 text-center text-gray-800 xs:text-sm md:text-base"
          >
            To begin your enquiry, let us know when you would like to visit
          </p>
        </div>
      </div>
    </div>
    <!-- end of TW header -->
    <hr class="block my-3 sm:hidden" />
    <div class="bg-white">
      <section class="text-gray-800">
        <div class="pt-1 pb-4 bg-white">
          <div class="flex flex-col items-center justify-center w-full">
            <!-- date -->
            <div class="flex flex-col items-center">
              <label
                for="date"
                class="block text-xs font-semibold leading-5 sm:text-sm"
                >Date of visit</label
              >
              <div
                class="relative border border-gray-300 rounded shadow-sm focus:ring focus:ring-red-300 focus:outline-none overflow-hiddenx md:rounded-md sm:mt-1"
              >
                <input
                  id="date"
                  v-model="booking.date"
                  type="date"
                  :min="today"
                  name="date"
                  class="block w-full p-2 text-xs font-medium bg-white sm:text-sm sm:leading-5"
                  placeholder="dd/mm/yyyy"
                  @blur="dateEntry"
                  @change="dateEntry"
                />
              </div>
            </div>

            <div
              class="relative flex flex-col items-center justify-between w-full px-4"
            >
              <!-- Conditions -->
              <div
                v-if="conditions"
                class="flex flex-col items-center justify-center p-1 my-2 border border-gray-300 rounded md:shadow md:px-4"
                :class="[
                  closedToday
                    ? 'text-tvap-pink bg-gray-50'
                    : 'text-tvap-green bg-green-50',
                ]"
              >
                <p
                  v-for="(condition, idx) in conditions.split('.')"
                  :key="idx"
                  class="text-xs font-semibold text-center sm:text-sm md:text-base"
                >
                  {{ condition | fmtCondition }}
                </p>
              </div>

              <!-- Sessions -->
              <div
                v-if="conditions && !closedToday"
                class="flex flex-col sm:mt-4"
              >
                <label
                  class="block text-xs font-semibold leading-5 sm:text-sm"
                  for="sessions"
                  >Please choose a session</label
                >
                <ul name="sessions" class="mt-1 space-y-2">
                  <li
                    v-for="(blocks, idx) in sessions"
                    :key="idx"
                    class="text-xs font-medium form-input sm:text-sm sm:leading-5"
                  >
                    <input
                      :ref="`input-${idx}`"
                      type="radio"
                      name="session"
                      @click="choose(idx)"
                    />
                    <span class="ml-3">From </span
                    ><span>{{ blocks[0].time }}</span>
                    <span>&nbsp;to&nbsp;</span><span>{{ blocks[1].time }}</span>
                  </li>
                </ul>
              </div>

              <!-- Visitors -->
              <div
                v-if="conditions && !closedToday"
                class="flex flex-col items-center mt-4 sm:justify-center sm:flex-row sm:mt-8 sm:w-full"
              >
                <!-- Playground Users -->
                <div class="flex flex-col items-center sm:w-1/4">
                  <label
                    for="users"
                    class="block text-xs font-semibold leading-5 sm:text-sm"
                  >
                    <div
                      class="relative flex items-center justify-center w-full"
                    >
                      <span>Playground Users</span>
                      <info-dropdown class="-right-6">
                        Please let us know how many children or adults with
                        special needs will be coming with you.
                      </info-dropdown>
                    </div>
                  </label>
                  <div class="relative rounded-md shadow-sm">
                    <div class="flex items-center py-0">
                      <select
                        aria-label="Playground Users"
                        class="h-full px-6 py-2 mx-2 text-xs bg-white border border-transparent border-gray-300 rounded md:rounded-md sm:text-sm sm:leading-5"
                        @change="selectedNumber"
                      >
                        <option
                          v-for="(no, idx) in [1, 2, 3, 4, 5, 6]"
                          id="`users-${idx}`"
                          :key="idx"
                          name="users"
                          >{{ no }}</option
                        >
                      </select>
                    </div>
                  </div>
                </div>

                <!-- Carers (note this input is swallowed - merely here as a visual distinction to users)-->
                <div class="flex flex-col items-center mt-1 md:mt-0 sm:w-1/4">
                  <label
                    for="carers"
                    class="block text-xs font-semibold leading-5 sm:text-sm"
                  >
                    <div
                      class="relative flex items-center justify-center w-full"
                    >
                      <span>Carers</span>
                    </div>
                  </label>
                  <div class="relative rounded-md shadow-sm">
                    <div class="flex items-center py-0">
                      <select
                        aria-label="Carers"
                        class="h-full px-6 py-2 mx-2 text-xs bg-white border border-transparent border-gray-300 rounded md:rounded-md sm:text-sm sm:leading-5"
                      >
                        <option
                          v-for="(no, idx) in [1, 2, 3, 4, 5, 6]"
                          :key="idx"
                          >{{ no }}</option
                        >
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="!available"
            class="flex items-center justify-center mt-4 mb-2 text-tvap-pink opacity-80"
          >
            <span class="text-sm font-semibold tracking-wide"
              >Sorry, no availability on this date</span
            >
          </div>

          <div
            class="flex flex-col items-center justify-center flex-shrink-0 pb-2 md:flex-row"
            :class="[!available ? 'mt-0' : 'mt-8']"
          >
            <div
              class="relative flex flex-col items-center justify-center w-1/2 text-center bg-red-300x md:flex-row"
            >
              <button
                type="button"
                class="inline-flex items-center px-4 py-2 text-sm font-medium leading-5 text-white border border-transparent rounded-md focus:outline-none focus:shadow-outline"
                :class="activeButton"
                :disabled="!canCheck"
                @click="check"
              >
                Check availability
              </button>
              <a
                href="#"
                class="relative self-center mt-4 text-xs font-semibold underline opacity-100 text-tvap-pink hover:opacity-80 md:absolute md:right-10 md:ml-6 md:mt-0"
                @click.prevent="cancel"
                >cancel</a
              >
            </div>
          </div>
        </div>
      </section>
    </div>
    <landing-modal
      v-if="current.matches('enquiring.landing')"
      @continue="landingContinue"
      @cancel="cancel"
    ></landing-modal>
  </div>
</template>

<script>
// eslint-disable-next-line
import bookingController from '@/controllers/booking-controller';
import InfoDropdown from '@/components/global/forms/info-dropdown';
import LandingModal from '@/components/landing-modal';
import moment from 'moment';
import { slotsForDate } from '@/lib/sessions';
import stateProps from '@/components/mixins/state-props';

export default {
  filters: {
    fmtCondition(condition) {
      return condition ? `${condition}.` : '';
    },
  },

  components: { InfoDropdown, LandingModal },

  mixins: [stateProps],

  data() {
    return {
      available: true,
      booking: {
        date: '',
        users: 1,
        arrive: { id: 0, time: '' },
        leave: { id: 0, time: '' },
      },
      closedToday: false,
      conditions: '',
      sessions: null,
      today: moment().format('YYYY-MM-DD'),
      visitDate: null,
    };
  },

  computed: {
    activeButton() {
      return this.canCheck
        ? 'bg-tvap-pink opacity-100 hover:opacity-80'
        : 'bg-tvap-pink opacity-50 pointer-events-none';
    },

    canCheck() {
      return (
        !this.closedToday &&
        !!this.booking.date &&
        !!this.booking.arrive.time &&
        !!this.booking.leave.time
      );
    },
  },

  methods: {
    cancel() {
      this.$service.send('LOGOFF');
    },

    async check() {
      const { date, users, arrive, leave } = this.booking;

      if (
        !arrive.time ||
        !leave.time ||
        !date ||
        !users ||
        !moment(date, 'YYYY-M-D').isValid()
      )
        return;

      // Check against Salesforce - returning false == no availability for that
      // number on the date requested.
      this.available = await bookingController.availability(this.booking);

      if (this.available) {
        this.$service.send('RESOLVE', { booking: this.booking });
      } else {
        this.$service.send('REJECT');
      }
    },

    choose(idx) {
      // nothing to choose if there are no sessions on the chosen day
      if (!Array.isArray(this.sessions) || this.sessions.length < 1) return;

      // show the user which one is chosen (in case this was triggered by the
      // user choosing the date)
      // this.$refs[`input-${idx}`][0].checked = true;

      this.booking.arrive.id = this.sessions[idx][0].id;
      this.booking.arrive.time = this.sessions[idx][0].time;
      this.booking.leave.id = this.sessions[idx][1].id;
      this.booking.leave.time = this.sessions[idx][1].time;
    },

    /**
     * Special code to allow the app to be used with restricted time slots and
     * not rewriting everything.
     * Simply return true if the timeslot is blocked
     */

    dateEntry(ev) {
      // switch off error message if it's there after previous failed check
      this.available = true;

      // disable the submit button, switch off all radio buttons & reset initial state
      this.booking.arrive.id = 0;
      this.booking.arrive.time = '';
      this.booking.leave.id = 0;
      this.booking.leave.time = '';
      Object.keys(this.$refs)
        .filter(k => k.startsWith('input-') && this.$refs[k].length)
        .map(k => this.$refs[k][0])
        .forEach(input => (input.checked = false));
      this.booking.users = 1;

      const dt = moment(ev.target.value);
      this.visitDate = dt.format('YYYY-MM-DD');

      // get back a nested array of all sessions for the given date
      const { slots, conditions } = slotsForDate(this.visitDate);
      this.sessions = slots;

      // TVAP sometimes opens on holidays i.e. days that would not normally be
      // open.
      // In thoses cases, the text and closed status from Salesforce needs to be
      // ignored and replaced with values from the slotsForDate function
      if (conditions) {
        this.closedToday = false;
        this.conditions = conditions;
      } else {
        const dayno = Number(dt.format('E'));
        const day = this.current.context.environment.filter(
          d => Number(d.dow) === dayno
        );
        this.closedToday = day[0].disabled;
        this.conditions = day[0].conditions;
      }
    },

    landingContinue() {
      this.$service.send('RESOLVE');
    },

    selectedNumber(ev) {
      this.booking.users = ev.target.value;
    },
  },
};
</script>
