<template>
  <div
    class="flex flex-col cursor-pointer"
    :class="position"
    @click="info = !info"
  >
    <span v-if="info" class="text-orange-500">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        class="w-4 fill-current"
      >
        <path
          d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm1.41-1.41A8 8 0 1 0 15.66 4.34 8 8 0 0 0 4.34 15.66zm9.9-8.49L11.41 10l2.83 2.83-1.41 1.41L10 11.41l-2.83 2.83-1.41-1.41L8.59 10 5.76 7.17l1.41-1.41L10 8.59l2.83-2.83 1.41 1.41z"
        />
      </svg>
    </span>
    <span v-else class="opacity-100 text-tvap-pink hover:opacity-80">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        class="w-4 fill-current"
      >
        <path
          d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm2-13c0 .28-.21.8-.42 1L10 9.58c-.57.58-1 1.6-1 2.42v1h2v-1c0-.29.21-.8.42-1L13 9.42c.57-.58 1-1.6 1-2.42a4 4 0 1 0-8 0h2a2 2 0 1 1 4 0zm-3 8v2h2v-2H9z"
        />
        <!-- following path is for the outline i icon -->
        <!-- <path
          d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"
        /> -->
      </svg>
    </span>
    <span
      v-if="info"
      class="absolute z-50 p-2 mt-6 text-sm text-gray-500 rounded-md shadow-lg right-4 bg-gray-50"
      :class="[wide ? 'w-64' : 'w-32']"
      ><slot></slot
    ></span>
  </div>
</template>

<script>
export default {
  props: {
    absolute: { type: Boolean, default: true },
    wide: { type: Boolean, default: false },
  },

  data() {
    return { info: false };
  },

  computed: {
    position() {
      return this.absolute ? 'absolute right-2 top-0' : 'relative';
    },
  },
};
</script>

<style lang="scss" scoped></style>
