<template>
  <transition name="modal">
    <div class="modal-mask fixed z-50 top-0 left-0 w-full h-screen table">
      <div class="table-cell align-middle">
        <div class="modal-container z-40 relative">
          <div class="mx-auto w-full sm:w-3/5 md:w-2/5 lg:w-1/3">
            <div class="sm:rounded overflow-hidden">
              <div class="bg-gray-100 text-sm lg:text-base text-gray-600">
                <div
                  :class="headerClasses"
                  class="flex justify-center px-2 py-1 font-bold"
                >
                  <slot name="header"></slot>
                </div>
                <div
                  :class="bodyClasses"
                  class="flex justify-center align-items px-2 pt-2 pb-4 font-semibold border-b"
                >
                  <slot name="body"></slot>
                </div>
                <div
                  :class="footerClasses"
                  class="flex justify-center px-2 pb-2 pt-3"
                >
                  <slot name="footer">
                    <button
                      :class="buttonClasses"
                      class="w-16 h-10 rounded-lg "
                      @click="accept"
                    >
                      OK
                    </button>
                  </slot>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'info',
    },
  },

  data() {
    return {
      styles: {
        dark: {
          header: ['bg-gray-200', 'text-gray-600'],
          body: ['bg-gray-50', 'border-gray-100', 'text-gray-500'],
          footer: ['bg-gray-50'],
          button: ['bg-gray-600', 'text-white', 'hover:bg-gray-500'],
        },
        indigo: {
          header: ['bg-indigo-700', 'text-white'],
          body: ['bg-indigo-50', 'border-indigo-100', 'text-indigo-600'],
          footer: ['bg-indigo-50'],
          button: ['bg-indigo-600', 'text-white', 'hover:bg-indigo-500'],
        },
        info: {
          header: ['bg-green-700', 'text-white'],
          body: ['bg-green-50', 'border-green-100', 'text-green-600'],
          footer: ['bg-green-50'],
          button: ['bg-green-600', 'text-white', 'hover:bg-green-500'],
        },
        warning: {
          header: ['bg-orange-500', 'text-orange-100'],
          body: ['bg-orange-200', 'border-orange-300'],
          footer: ['bg-orange-200'],
          button: ['bg-orange-400', 'text-white', 'hover:text-orange-200'],
        },
        error: {
          header: ['bg-red-600', 'text-red-100'],
          body: ['bg-white', 'border-white', 'text-red-500'],
          footer: ['bg-white'],
          button: ['bg-orange-400', 'text-white', 'hover:text-orange-200'],
        },
      },
    };
  },

  computed: {
    bodyClasses() {
      return this.getStyle()['body'];
    },
    buttonClasses() {
      return this.getStyle()['button'];
    },
    footerClasses() {
      return this.getStyle()['footer'];
    },
    headerClasses() {
      return this.getStyle()['header'];
    },
  },

  methods: {
    accept() {
      this.$emit('accept');
    },

    getStyle() {
      return this.styles[this.type] || this.styles.info;
    },
  },
};
</script>

<style>
.modal-cover {
  background-color: rgba(0, 0, 0, 0.1);
  transition: opacity 0.2s ease;
}
.modal-mask {
  background-color: rgba(0, 0, 0, 0.4);
  transition: opacity 0.2s ease;
}

.modal-container {
  transition: all 0.2s ease;
}

/*
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
