'use strict';
import jwtDecode from 'jwt-decode';
// eslint-disable-next-line
import { Machine, assign, actions } from 'xstate';
// eslint-disable-next-line
const { log } = actions;
import { http, store } from '@/lib';

const ping = async () => {
  // See if we get any response...
  return await http.get('/info/health');
};

/**
 * Environment means any server side settings, config needed for the app e.g.
 * details of open days and any conditions.
 */
const environment = async () => {
  const environment = await http.get('/workingweek');
  return environment.data.results;
};

const readUser = async ctx => {
  try {
    const user = await http.get(`/user/${ctx.token.id}`);
    return user.data.message.email === ctx.token.id;
  } catch (error) {
    // This is the expected result - the user doesn't already exist so go ahead
    // and allow it to be created.
    return false;
  }
};

const session = async () => {
  // No error thrown? Server is up so get token (short expiry)...
  const session = await http.get('/session/start');

  // Add the token to the Axios object & return the token to be stored in the
  // context
  http.setToken(session.data.token);

  //TODO replace the block above with this...
  return jwtDecode(session.data.token);
};

/**
 * Invoked service which checks if the user can stay logged in
 */
const checkSession = async ctx => {
  const token = store.token.raw();
  if (token) {
    try {
      await http.post('/verify', { token });
      if (store.token.get().session) {
        return { token };
      }
    } catch (error) {
      // At this point we have an expired token. The user may or may not have
      // logged in by now - if they have then renew the token.
      const loggedIn = store.token.get().session ? true : false;
      http.removeToken();
      store.token.delete();
      if (loggedIn) {
        const extended = await session();
        ctx.token = extended;
        store.token.set(extended);
      }
    }
  }
  throw Error('No token found');
};

/**
 * Sent when a user gets to the final screen but there is something wrong with
 * the presented data and they are unable to complete the request.
 *
 * @param {*} ctx
 * @param {*} ev
 */
const sendEmail = async (ctx, ev) => {
  return await http.post('/booking/error', ev.data);
};

const machine = Machine(
  {
    id: 'booking',
    initial: 'idle',
    context: {
      booking: '',
      exists: false,
      href: window.location.href,
      landed: false,
      path: '',
      token: '',
      userid: '',
    },
    states: {
      idle: {
        on: {
          START: {
            target: 'preparing',
          },
        },
      },

      // Make sure the server is up and running and get back a short term token.
      preparing: {
        entry: [assign({ path: (_, e) => e.path, token: (_, e) => e.token })],
        initial: 'connecting',
        states: {
          connecting: {
            invoke: {
              src: ping,
              onDone: {
                target: '#booking.online',
              },
              onError: {
                target: 'failure',
              },
            },
          },
          failure: {
            on: {
              DISMISS: {
                target: '#booking.idle',
              },
            },
          },
        },
      },

      // Entry could be via an email link (path='password') or online (enquiry)
      online: {
        on: {
          '': [
            {
              target: 'password',
              cond: c => {
                return c.path === 'password';
              },
            },
            { target: 'start_session' },
          ],
        },
      },

      start_session: {
        invoke: {
          src: session,
          onDone: {
            target: 'load_environment',
            actions: [assign({ token: (_, ev) => ev.data })],
          },
          onError: {
            target: 'preparing.failure',
          },
        },
      },

      load_environment: {
        invoke: {
          src: environment,
          onDone: {
            target: 'prelogin',
            actions: [assign({ environment: (_, ev) => ev.data })],
          },
          onError: {
            target: 'preparing.failure',
          },
        },
      },

      prelogin: {
        on: {
          '': [
            { target: 'enquiring', cond: 'loggedIn' },
            { target: 'enquiring.landing' },
          ],
        },
      },

      // An unknown user is checking availability.
      enquiring: {
        on: {
          RESOLVE: { target: 'checking' }, // yes, availability
          REJECT: { target: '.retry', internal: true }, // no availability
          LOGOFF: { target: 'done' },
        },
        initial: 'pending',
        states: {
          landing: {
            on: {
              RESOLVE: { target: 'pending' },
            },
          },
          pending: {
            entry: 'resetContext',
          },
          retry: {
            on: {
              RESOLVE: {
                target: '#booking.checking',
              },
              REJECT: { target: 'retry', internal: true },
            },
          },
        },
      },

      checking: {
        entry: ['setBooking'],
        invoke: {
          src: checkSession,
          onDone: {
            target: 'submit_idle',
            // Token is held in data object of event not on event itself
            actions: [
              assign({
                token: (_, ev) => jwtDecode(ev.data.token),
                raw: (_, ev) => ev.data.token,
              }),
            ],
          },
          onError: {
            target: 'authenticating',
          },
        },
      },

      // There is availability so get the user logged in or registered.
      authenticating: {
        // entry: ['setBooking'],
        on: {
          FORGOT: 'forgotten',
          REGISTER: 'registering',
          LOGIN: '.login',
        },
        initial: 'login',
        states: {
          login: {
            on: {
              RESOLVE: {
                target: '#booking.submit_idle',
                actions: ['setToken', 'persist'],
              },
              REJECT: { target: 'noauth', internal: true },
            },
          },
          noauth: {
            on: {
              RESOLVE: {
                target: '#booking.submit_idle',
                actions: ['setToken', 'persist'],
              },
              REJECT: { target: 'noauth', internal: true },
            },
          },
        },
      },

      // User enters specific booking details e.g. for each child.
      submit_idle: {
        entry: [
          // log(({ token }) => token),
        ],
        on: {
          CANCEL: { target: 'enquiring.pending' },
          NOT_SHOWN: '.blocked',
          REJECT: '.failure',
          LOGOFF: { target: 'done' },
        },
        initial: 'pending',
        states: {
          blocked: {
            on: {
              DISMISS: '#booking.preparing',
            },
          },
          failure: {
            on: {
              DISMISS: '#booking.preparing',
            },
          },
          pending: {
            on: {
              UPDATE: { target: 'processing' },
              EMAIL: { target: 'email' },
            },
          },
          processing: {
            on: {
              RESOLVE: 'processed',
              REJECT: 'expired',
            },
          },
          processed: {
            on: {
              RESOLVE: '#booking.preparing',
            },
          },
          email: {
            invoke: {
              src: sendEmail,
              onDone: {
                target: '#booking.preparing',
              },
              onError: {
                target: 'expired',
              },
            },
          },
          expired: {
            on: {
              LOGIN: '#booking.online',
              CANCEL: '#booking.preparing',
            },
          },
        },
      },

      // There is availability but the user has forgotten their password.
      forgotten: {
        exit: 'resetContext',
        initial: 'pending',
        states: {
          pending: {
            on: {
              CREATE: '#booking.registering',
              SUBMIT: 'submitted',
              DISMISS: '#booking.done',
            },
          },
          submitted: {
            on: {
              DISMISS: '#booking.done',
            },
          },
        },
      },

      // There is availability but the user doesn't yet have an account.
      registering: {
        initial: 'pending',
        states: {
          pending: {
            on: {
              CANCEL: '#booking.done',
              REJECT: [
                {
                  target: '#booking.new_lead',
                  actions: assign({ userid: (_, ev) => ev.userid }),
                  cond: (_, ev) => ev.message === 'not_in_salesforce',
                },
                {
                  target: 'already_registered',
                  cond: (_, ev) => ev.message === 'user_exists',
                },
                {
                  target: 'retry',
                  cond: (_, ev) => ev.message === 'missing_userid',
                },
              ],
              RESOLVE: 'submitted',
            },
          },
          already_registered: {
            on: {
              CANCEL: '#booking.done',
              RESET: '#booking.forgotten',
              RETRY: 'pending',
            },
          },
          retry: {
            on: {
              RETRY: 'pending',
            },
          },
          submitted: {
            on: {
              DISMISS: '#booking.done',
            },
          },
        },
      },

      new_lead: {
        on: {
          CANCEL: '#booking.done',
        },
      },

      password: {
        entry: 'extractBooking',
        on: {
          '': [
            { target: 'reset_password', cond: c => c.token.type === 'reset' },
            { target: 'pre_set_password' },
          ],
        },
      },

      reset_password: {
        on: {
          DISMISS: '#booking.done',
          RESOLVE: {
            target: '#booking.preparing',
            actions: ['persist'],
          },
        },
      },

      pre_set_password: {
        invoke: {
          src: readUser,
          onDone: {
            target: 'set_password',
            actions: assign({ exists: (_, ev) => ev.data }),
          },
          onError: {
            actions: 'logger',
          },
        },
      },

      set_password: {
        on: {
          DISMISS: '#booking.done',
          RESET: '#booking.forgotten',
          RESOLVE: {
            target: '#booking.preparing',
            actions: ['persist'],
          },
        },
      },

      done: {
        entry: 'logoff',
        type: 'final',
      },
    },
  },
  {
    actions: {
      extractBooking: assign(ctx => ({
        booking: ctx.token.booking,
        token: { id: ctx.token.id, type: ctx.token.type },
      })),
      setBooking: assign((_, ev) => ({
        booking: ev.booking,
      })),
      // decoded token saved in context
      setToken: assign((_, ev) => ({
        token: jwtDecode(ev.token),
        raw: ev.token,
      })),
      resetContext: assign({
        booking: '',
        path: '',
        landed: true,
      }),
      logger: log((ctx, ev) => ev),
      // raw token saved in store
      persist: (_, ev) => store.token.set(ev.token),
      logoff: () => {
        store.token.delete();
        window.location.href = process.env.VUE_APP_DOMAIN;
      },
    },

    guards: {
      loggedIn: c => c.raw && jwtDecode(c.raw).session,
    },
  }
);

export default machine;
