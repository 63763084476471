<template>
  <div class="flex flex-col justify-between items-center h-screen">
    <div class="flex flex-col items-center mt-2 lg:mt-4 w-full">
      <div
        class="flex flex-col w-full md:w-11/12 p-3 lg:px-8 lg:py-4 bg-white md:rounded-lg md:shadow-xl"
      >
        <div class="mb-1 lg:mb-2">
          <h2 class="text-xl lg:text-2xl text-center text-blue-800">
            Finish setting up your TVAP account
          </h2>
        </div>

        <div class="flex justify-center items-center">
          <span class="inline-flex mr-2 text-xs md:text-sm text-gray-500"
            >Login email address:</span
          >
          <span
            class="inline-flex text-xs md:text-sm font-semibold text-gray-700"
          >
            {{ current.context.token.id }}
          </span>
        </div>

        <div
          v-if="error !== ''"
          class="flex justify-center mt-2 text-orange-700"
        >
          <span class="ml-1 text-sm font-semibold">{{ error }}</span>
        </div>

        <div
          v-if="current.context.exists"
          class="mt-4 flex flex-col justify-center items-center"
        >
          <span class="text-orange-700 text-sm font-semibold"
            >This email address has already been registered</span
          >
          <div class="flex justify-center items-center mt-6">
            <div class="rounded-md shadow-sm">
              <button
                class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                @click="reset"
              >
                Reset password
              </button>
            </div>
            <div class="ml-6">
              <a
                href="#"
                class="underline text-gray-500 text-sm"
                @click.prevent="cancel"
                >cancel</a
              >
            </div>
          </div>
        </div>

        <div v-else>
          <!--
          <div class="lg:mt-4 text-gray-600">
            <span class="text-sm font-semibold">
              New password for your account
            </span>
          </div>
          -->

          <div class="flex items-center justify-left text-center mt-1 lg:mt-6">
            <div class="w-full">
              <input
                ref="password"
                v-model="password"
                class="w-full py-1 pl-10 text-base border-2 border-gray-300 rounded-lg focus:border-orange-300 focus:shadow-xl"
                type="password"
                name="password"
                placeholder="Password"
              />
            </div>
            <div class="absolute">
              <svg
                class="h-6 w-6 p-1 ml-2 text-gray-600"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  class="fill-current"
                  d="M4 8V6a6 6 0 1 1 12 0v2h1a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-8c0-1.1.9-2 2-2h1zm5 6.73V17h2v-2.27a2 2 0 1 0-2 0zM7 6v2h6V6a3 3 0 0 0-6 0z"
                />
              </svg>
            </div>
            <div class="text-blue-400" @click="toggle('password')">
              <template v-if="showing.password">
                <svg
                  class="fill-current inline-block h-8 w-8 md:h-10 md:w-10 ml-2 px-1 py-1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M12.81 4.36l-1.77 1.78a4 4 0 0 0-4.9 4.9l-2.76 2.75C2.06 12.79.96 11.49.2 10a11 11 0 0 1 12.6-5.64zm3.8 1.85c1.33 1 2.43 2.3 3.2 3.79a11 11 0 0 1-12.62 5.64l1.77-1.78a4 4 0 0 0 4.9-4.9l2.76-2.75zm-.25-3.99l1.42 1.42L3.64 17.78l-1.42-1.42L16.36 2.22z"
                  />
                </svg>
              </template>
              <template v-else>
                <svg
                  class="fill-current inline-block h-8 w-8 md:h-10 md:w-10 ml-2 px-1 py-1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M 0.2 10 a 11 11 0 0 1 19.6 0 A 11 11 0 0 1 0.2 10 Z m 9.8 4 a 4 4 0 1 0 0 -8 a 4 4 0 0 0 0 8 Z m 0 -2 a 2 2 0 1 1 0 -4 a 2 2 0 0 1 0 4 Z"
                  />
                </svg>
              </template>
            </div>
          </div>

          <div class="flex mt-4 lg:mt-6 items-center justify-left text-center">
            <div class="w-full">
              <input
                ref="confirm"
                v-model="confirm"
                class="w-full py-1 pl-10 text-base border-2 border-gray-300 rounded-lg focus:border-orange-300 focus:shadow-xl"
                type="password"
                name="confirm"
                placeholder="Please repeat password"
              />
            </div>
            <div class="absolute">
              <svg
                class="h-6 w-6 p-1 ml-2 text-gray-600"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  class="fill-current"
                  d="M4 8V6a6 6 0 1 1 12 0v2h1a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-8c0-1.1.9-2 2-2h1zm5 6.73V17h2v-2.27a2 2 0 1 0-2 0zM7 6v2h6V6a3 3 0 0 0-6 0z"
                />
              </svg>
            </div>
            <div class="text-blue-400" @click="toggle('confirm')">
              <template v-if="showing.confirm">
                <svg
                  class="fill-current inline-block h-8 w-8 md:h-10 md:w-10 ml-2 px-1 py-1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M12.81 4.36l-1.77 1.78a4 4 0 0 0-4.9 4.9l-2.76 2.75C2.06 12.79.96 11.49.2 10a11 11 0 0 1 12.6-5.64zm3.8 1.85c1.33 1 2.43 2.3 3.2 3.79a11 11 0 0 1-12.62 5.64l1.77-1.78a4 4 0 0 0 4.9-4.9l2.76-2.75zm-.25-3.99l1.42 1.42L3.64 17.78l-1.42-1.42L16.36 2.22z"
                  />
                </svg>
              </template>
              <template v-else>
                <svg
                  class="fill-current inline-block h-8 w-8 md:h-10 md:w-10 ml-2 px-1 py-1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M 0.2 10 a 11 11 0 0 1 19.6 0 A 11 11 0 0 1 0.2 10 Z m 9.8 4 a 4 4 0 1 0 0 -8 a 4 4 0 0 0 0 8 Z m 0 -2 a 2 2 0 1 1 0 -4 a 2 2 0 0 1 0 4 Z"
                  />
                </svg>
              </template>
            </div>
          </div>

          <div class="flex items-center mt-4 text-green-500">
            <span class="ml-1 font-semibold text-sm"
              >Password must be 8-16 characters and must contain a mix of
              uppercase and lowercase characters and at least one number</span
            >
          </div>

          <div class="mt-8 mb-4 flex justify-center items-center">
            <div class="rounded-md shadow-sm">
              <button
                v-if="ready"
                class="py-2 px-8 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                @click="save"
              >
                Save
              </button>
              <button
                v-else
                class="opacity-50 cursor-not-allowed py-2 px-8 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600"
              >
                Save
              </button>
            </div>
            <div class="ml-6">
              <a
                href="#"
                class="underline text-gray-500 text-sm"
                @click.prevent="cancel"
                >cancel</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="block">
      <span class="text-gray-500 text-xxs md:text-xs"
        >By registering with TVAP, you agree to our
        <a class="underline" href="#"> terms of service.</a></span
      >
    </footer>

    <modal v-if="canLogin" type="dark">
      <div slot="header">
        <span>Registration complete</span>
      </div>
      <div slot="body">
        <p>
          Your details have been saved. Click below to enquire on current
          availability.
        </p>
      </div>
      <div slot="footer">
        <button
          class="mb-2 md:mb-3 py-2 px-2 md:px-8 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
          @click="login"
        >
          OK
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import { http } from '@/lib';
import Modal from '@/components/modal.vue';
import stateProps from '@/components/mixins/state-props';

export default {
  components: {
    Modal,
  },

  mixins: [stateProps],

  data() {
    return {
      canLogin: false,
      confirm: '',
      error: '',
      password: '',
      showing: { confirm: false, password: false },
    };
  },

  computed: {
    ready() {
      return this.valid();
    },
  },

  methods: {
    cancel() {
      this.$service.send('DISMISS');
    },

    async login() {
      this.canLogin = false;
      // Password has been set - now fake a login so the user won't have to jump
      // through that loop after next enquiry
      const resp = await http.post('password', {
        id: this.current.context.token.id,
        pw: this.password,
        persist: true,
      });
      http.setToken(resp.data.token);
      this.$service.send('RESOLVE', {
        token: resp.data.token,
      });
    },

    reset() {
      this.$service.send('RESET');
    },

    async save() {
      // 3 possible user statuses:
      //   'new' The user has signed up and a temp record has been created
      //   'pending' The user has entered the OTP and chosen a password
      //   'active' The user has completed their personal info

      //* 18 Feb 2020
      //* status set to active for new users, to bypass the personal info screen
      //* now that users must exist in Salesforce it doesn't seem useful to ask
      //* again - maybe this will be replaced with a screen showing Salesforce
      //* data and asking for confirmation?

      const body = {
        id: this.current.context.token.id,
        user: {
          id: this.current.context.token.id,
          password: this.password,
          status: 'active',
        },
      };

      if (this.valid()) {
        try {
          await http.post('password/set', body);
          this.canLogin = true;
        } catch (error) {
          console.log(error);
          if (error.status === 401) {
            this.error = 'Weak password';
          } else {
            this.error = 'An error has occurred. Please start again.';
          }
        }
      } else {
        this.error = 'Passwords do not match';
      }
    },

    toggle(field) {
      // show/hide the password being entered
      const input = this.$refs[field];
      input.type = input.type === 'text' ? 'password' : 'text';

      // set a flag to control which icon is used to toggle the password display
      this.showing[field] = this.$refs[field].type === 'text';
    },

    valid() {
      return this.password.length && this.password === this.confirm;
    },
  },
};
</script>

<style lang="scss" scoped></style>
