<template>
  <transition name="modal">
    <div class="fixed top-0 left-0 z-50 w-full h-full modal-mask">
      <div class="h-full mt-8">
        <div class="relative z-40 h-full modal-container">
          <div
            id="wrapperx"
            class="w-full h-auto mx-auto overflow-y-scroll text-xs text-gray-600 bg-white letterbox:h-5/6 lg:w-11/12 xl:w-2/3 lg:rounded-lg sm:text-sm"
          >
            <header
              class="py-1 pl-8 text-sm font-semibold bg-gray-100 border-b border-gray-200 sm:py-2 sm:text-base md:text-lg"
            >
              <span>Please read carefully</span>
            </header>
            <section class="px-2 pb-2 sm:px-8 sm:pt-4 xs:pb-4 sm:pb-6 ">
              <h4 class="my-1 text-xs font-semibold sm:text-base">
                In order to use this app to request a date to visit us:
              </h4>
              <ul class="mb-2 font-normal list-disc">
                <li
                  class="ml-3 leading-tight sm:ml-6 text-xxs sm:text-xs md:text-base sm:leading-relaxed"
                >
                  You must have registered your email address with TVAP. This
                  must be done over the phone or in person in order for us to
                  set up your on-line access correctly.
                </li>
                <li
                  class="ml-3 leading-tight sm:ml-6 text-xxs sm:text-xs md:text-base sm:leading-relaxed"
                >
                  You can only use this App (at present) to make an enquiry for
                  your family or for an accompanied visit for a child that you
                  care for.
                </li>
                <li
                  class="ml-3 leading-tight sm:ml-6 text-xxs sm:text-xs md:text-base sm:leading-relaxed"
                >
                  You cannot book the sensory room with this App.
                </li>
                <li
                  class="ml-3 leading-tight sm:ml-6 text-xxs sm:text-xs md:text-base sm:leading-relaxed"
                >
                  Your child(ren)/charge(s) must meet the criteria for the
                  chosen day and have already visited us in the past so that we
                  have all the relevant information about them.
                </li>
                <li
                  class="ml-3 leading-tight sm:ml-6 text-xxs sm:text-sm md:text-base sm:leading-relaxed"
                >
                  The first time you use this App you will be required to set up
                  credentials for logging in.
                </li>
              </ul>
              <p class="mt-1 sm:mt-4 text-xxs sm:text-sm md:text-base ">
                Please note that Fridays are reserved for adults with special
                needs.
              </p>
              <p
                class="mt-1 font-medium sm:mt-2 text-xxs sm:text-sm md:text-base"
              >
                Please note that currently we are open on Fridays, Saturdays and
                Sundays.
              </p>
              <p class="mt-1 sm:mt-2 text-xxs sm:text-sm md:text-base ">
                For our full opening hours and timetable see
                <a
                  href="https://tvap.org.uk/about-us/opening-times-and-day-information"
                  class="underline"
                  >here</a
                >.
              </p>
              <p
                class="my-1 font-semibold sm:my-4 text-xxs sm:text-sm md:text-base "
              >
                Using this App will not guarantee you a space. It is not a
                booking.
              </p>
              <p class="text-xxs sm:text-sm md:text-base ">
                This App will check potential availability on any given day but
                will not check whether others have also requested the same
                day/time before you, nor can it determine the appropriate mix of
                children.
              </p>
              <p class="sm:mt-2 text-xxs sm:text-sm md:text-base ">
                Your enquiry will be held in a queue together with the other
                enquiries for the same day and dealt with on our next working
                day, in chronological order. We cannot guarantee that all
                requests will result in confirmed bookings but we will deal with
                all enquiries fairly and in accordance with our admissions
                policy to ensure an appropriate mix of children on the
                Playground at any time.
              </p>
              <p class="sm:mt-2 text-xxs sm:text-sm md:text-base ">
                We will contact you by email to either confirm your booking
                enquiry is now a firm booking or to inform you that,
                unfortunately on this occassion we cannot offer you a space. If
                you do not hear from us by the end of the next working day
                please give us a ring on {{ tvapPhone }} to check.
              </p>
              <div class="flex justify-center mt-1 sm:mt-8 sm:mb-4">
                <button
                  class="px-2 py-1 text-xs font-medium text-white transition duration-150 ease-in-out border border-transparent opacity-100 md:rounded-md md:px-4 md:py-2 md:text-sm bg-tvap-pink hover:opacity-80 focus:outline-none focus:border-tvap-pink focus:shadow-outline-tvap-pink active:bg-tvap-pink"
                  @click="click(true)"
                >
                  Continue
                </button>
                <button
                  class="ml-6 text-gray-500 underline"
                  @click="click(false)"
                >
                  cancel
                </button>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      tvapPhone: '01628 628599',
    };
  },

  methods: {
    click(next) {
      this.$emit(next ? 'continue' : 'cancel');
    },
  },
};
</script>

<style scoped>
@media (max-height: 800px) {
  div#wrapper {
    width: 100% !important;
    border-radius: 0 !important;
  }
}
.modal-cover {
  background-color: rgba(0, 0, 0, 1);
  transition: opacity 0.2s ease;
}
.modal-mask {
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.2s ease;
}

.modal-container {
  transition: all 0.2s ease;
}

/*
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
